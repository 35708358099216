export default {
  Billing_to: 'Bill To',
  Dashboards: 'Dashboards',
  Dashboard: 'Dashboard',
  CRM: 'CRM',
  Analytics: 'Analytics',
  eCommerce: 'eCommerce',
  Calendar: 'Calendar',
  Chat: 'Chat',
  chat_support: 'Chat ',
  phone_support: 'Phone Number Support',
  'Phone Number': '(888) 965-3262',
  Email: 'Email',
  Invoice: 'Invoice',
  Invoice_to: 'Invoice To',
  Receipt: 'Receipt',
  List: 'List',
  Preview: 'Preview',
  Edit: 'Edit',
  Add: 'Add',
  User: 'User',
  'User List': 'User List',
  'User View': 'User View',
  'APPS AND PAGES': 'APPS AND PAGES',
  Pages: 'Pages',
  Authentication: 'Authentication',
  'Login V1': 'Login V1',
  'Login V2': 'Login V2',
  'Register V1': 'Register V1',
  'Register V2': 'Register V2',
  'Forgot Password V1': 'Forgot Password V1',
  'Forgot Password V2': 'Forgot Password V2',
  'Reset Password V1': 'Reset Password V1',
  'Reset Password V2': 'Reset Password V2',
  Miscellaneous: 'Miscellaneous',
  'Under Maintenance': 'Under Maintenance',
  Error: 'Error',
  'Coming Soon': 'Coming Soon',
  'Not Authorized': 'Not Authorized',
  'Knowledge Base': 'Knowledge Base',
  'Account Settings': 'Account Settings',
  Pricing: 'Pricing',
  FAQ: 'FAQ',
  'USER INTERFACE': 'USER INTERFACE',
  Typography: 'Typography',
  Icons: 'Icons',
  Gamification: 'Gamification',
  Cards: 'Cards',
  Basic: 'Basic',
  Statistics: 'Statistics',
  Advance: 'Advance',
  Actions: 'Actions',
  Action: 'Action',
  Chart: 'Chart',
  Components: 'Components',
  Alert: 'Alert',
  Avatar: 'Avatar',
  Badge: 'Badge',
  Button: 'Button',
  Menu: 'Menu',
  'Expansion Panel': 'Expansion Panel',
  Dialog: 'Dialog',
  Pagination: 'Pagination',
  Tabs: 'Tabs',
  Tooltip: 'Tooltip',
  Chip: 'Chip',
  Snackbar: 'Snackbar',
  Stepper: 'Stepper',
  Timeline: 'Timeline',
  Treeview: 'Treeview',
  'FORMS & TABLES': 'FORMS & TABLES',
  'Forms Elements': 'Forms Elements',
  Autocomplete: 'Autocomplete',
  Checkbox: 'Checkbox',
  Combobox: 'Combobox',
  'File Input': 'File Input',
  Radio: 'Radio',
  'Range Slider': 'Range Slider',
  Select: 'Select',
  Slider: 'Slider',
  Switch: 'Switch',
  Textarea: 'Textarea',
  Textfield: 'Textfield',
  'Date Picker': 'Date Picker',
  'Month Picker': 'Month Picker',
  'Time Picker': 'Time Picker',
  Rating: 'Rating',
  'Form Layout': 'Form Layout',
  'Form validation': 'Form validation',
  Tables: 'Tables',
  'Simple Table': 'Simple Table',
  Datatable: 'Datatable',
  'CHARTS AND MAPS': 'CHARTS AND MAPS',
  Charts: 'Charts',
  'Apex Chart': 'Apex Chart',
  Chartjs: 'Chartjs',
  'Leaflet Maps': 'Leaflet Maps',
  Others: 'Others',
  'Menu Levels': 'Menu Levels',
  'Menu Level 2.1': 'Menu Level 2.1',
  'Menu Level 2.2': 'Menu Level 2.2',
  'Menu Level 3.1': 'Menu Level 3.1',
  'Menu Level 3.2': 'Menu Level 3.2',
  'Disabled Menu': 'Disabled Menu',
  'Raise Support': 'Raise Support',
  Documentation: 'Documentation',
  'Charts & Maps': 'Charts & Maps',
  Apps: 'Apps',
  'User Interface': 'User Interface',
  'Forms & Tables': 'Forms & Tables',
  'Buttom Options': 'Buttom Options',
  VIEWS: 'VIEWS',
  Users: 'Users',
  Transactions: 'Transactions',
  'Access Control': 'Access Control',
  'Payment Methods': 'Payment Methods',
  'Order Video': 'Order Video',
  'Order Videos': 'Order Videos',
  'Order History': 'Order History',
  'Payment History': 'Payment History',
  Configurations: 'Configurations',
  Sports: 'Sports',
  Ranking: 'Ranking',
  'Ranking Groups': 'Ranking Groups',
  'Cancellation Reasons': 'Cancellation Reasons',
  Fields: 'Fields',
  'Field Monitoring': 'Field Monitoring',
  Facilities: 'Facilities',
  Cameras: 'Cameras',
  'App Users': 'App Users',
  'Console Users': 'Console Users',
  'User Activity': 'User Activity',
  Highlights: 'Highlights',
  Groups: 'Groups',
  Roles: 'Roles',
  Notifications: 'Notifications',
  Reservations: 'Reservations',
  Booking: 'Booking',
  Bookings: 'Bookings',
  'Regular Booking': 'Regular Booking',
  'List For Sale': 'List For Sale',
  'Block Times': 'Block Times',
  'Recurring Reservations': 'Recurring Reservations',
  'Recurring Bookings': 'Recurring Bookings',
  Metrics: 'Metrics',
  Communications: 'Communications',
  'Onsite Payments Methods': 'Onsite Payments Methods',
  'Blocked Times': 'Blocked Times',
  'Video Blocked Times': 'Video Blocked Times',
  'Console Notifications': 'Console Notificaciones',
  'App Notifications': 'App Notifications',
  'Recurring Orders': 'Recurring Orders',
  'Players Group': 'Players Group',
  Reservation: 'Reservation',

  FACILITY: 'FACILITY',
  Schedule: 'Schedule',
  Contacts: 'Contacts',
  Contact: 'Contact',
  Categories: 'Categories',
  'Contact Categories': 'Contacts Categories',
  'Contact Category': 'Contact Category',
  Category: 'Category',
  Settings: 'Settings',
  ORGANIZATION: 'ORGANIZATION',
  Reports: 'Reports',
  Billing: 'Billing',
  'Billing History': 'Billing History',
  Sales: 'Sales',
  Inventory: 'Inventory',
  current_inventory: 'Inventario Actual',
  'Products Categories': 'Product Categories',
  Products: 'Products',
  'Inventory Adjustment': 'Inventory Adjustment',
  'POS Settings': 'POS Settings',
  FANATY: 'FANATY',
  SUPPORT: 'SUPPORT',
  Notification: 'Notification',
  Organizations: 'Organizations',
  Application: 'Application',
  Updates: 'Updates',
  POS: 'POS',
  Support: 'Support',
  'Full Videos': 'Full Videos',
  Recordings: 'Recordings',
  'Org Settings ***': 'Org Settings',
  'Facility Settings ***': 'Facility Settings ***',
  'Payments ***': 'Payments ***',
  'Contacts ***': 'Contacts ***',
  v2: 'v2',

  Players: 'Players',
  Videos: 'Videos',
  Payments: 'Payments',
  'Fanaty Payments': 'Fanaty Payments',
  'POS Payments': 'POS Payments',
  'Facility Payments': 'Facility Payments',
  Checkout: 'Checkout',
  Activity: 'Activity',
  Terminals: 'Terminals',
  Loading: 'Loading',
  search: 'Search',
  actions: 'Actions',
  locale: 'en-US',
  filter_by: 'filter by',
  minutes: {
    min_2: '2 Minutes',
    min_5: '5 Minutes',
    min_60: '60 Minutes',
    min_90: '90 Minutes',
    min_120: '120 Minutes',
    min_150: '150 Minutes',
    min_180: '180 Minutes',
    min_210: '210 Minutes',
    min_240: '240 Minutes',
    min_270: '270 Minutes',
    min_300: '300 Minutes',
    min_330: '330 Minutes',
    min_360: '360 Minutes',
  },
  required: {
    null: ' is required',
    'length 0': ' must be less than 0 characters',
    'length 3': ' must be less than 3 characters',
    'length 4': ' must be less than 4 characters',
    'length 5': ' must be less than 5 characters',
    'length 10': ' must be less than 10 characters',
    'length 15': ' must be less than 15 characters',
    'length 20': ' must be less than 20 characters',
    'length 25': ' must be less than 25 characters',
    'length 40': ' must be less than 40 characters',
    'length 50': ' must be less than 50 characters',
    'length 100': ' must be less than 100 characters',
    'length 200': ' must be less than 200 characters',
    'length 250': ' must be less than 250 characters',
    length: ' must be less than or equal to ',
    email: 'E-mail must be valid',
    nullGeneral: 'This field is required',
    invalidFormat: 'Invalid Format',
    label: '(*) Required',
    labelShort: '(*) Req.',
    needData: 'Missing data to add, please complete them',
  },
  tooltip: {
    new: 'New',
    add: 'Add',
    create: 'Create',
    save: 'Save',
    edit: 'Edit',
    update: 'Update',
    delete: 'Delete',
    show: 'Detail',
    cancel: 'Cancel',
    open_tab: 'Open Tab',
    next_tab: 'Next Tab',
    back: 'Back',
    list: 'List',
    back_table: 'Back to List',
    info: 'Information',
    search: 'Search',
    actions: 'Actions',
    refresh: 'Refresh',
    left: 'Left',
    right: 'Right',
    order: 'Order',
    no_data: 'There are no videos to show',
    enable: 'Enable',
    disable: 'Disable',
    submit: 'Submit',
    default: 'Default',
    copied: 'Copied',
    not_copy: 'Can not copy',
    view_detail: 'View Detail',
    view_video: 'View Video',
    confirm_order: 'Confirm Order',
    edit_order: 'Edit Order',
    close: 'Close',
    closed: 'Closed',
    download: 'Download',
    print: 'Print',
    print_selection: 'Print Selection',
    generating_pdf: 'Generating PDF',
    continue: 'Continue',
    print_receivet: 'Print receipt',
    generate_report: 'Generate Report',
    play_video: 'Play video',
    yesterday: 'Yesterday',
    today: 'Today',
    tomorrow: 'Tomorrow',
    this_week: 'This Week',
    next_week: 'Next Week',
    prev_week: 'Prev Week',
    prev: 'Prev',
    next: 'Next',
    export: 'Export',
    filters: 'Filters',
    accept: 'Accept',
    confirm: 'Confirm',
    add_image: 'Add Image',
    detail: 'Detail',
    exit: 'Exit',
    send: 'Send',
    resend: 'Resend',
    share: 'Share',
    at_minute: 'at minute',
    enabled: 'Enabled',
    disabled: 'Disabled',
    mark_as_paid: 'Mark as paid',
    mark_all: 'Mark all',
    uncheck_all: 'Uncheck all',
    unmark_all: 'Uncheck all',
    all: 'All',
    unpaid: 'Unpaid',
  },
  weekdays: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
  },
  weekdays_short: {
    mon: 'Mon',
    tue: 'Tue',
    wed: 'Wed',
    thu: 'Thu',
    fri: 'Fri',
    sat: 'Sat',
    sun: 'Sun',
  },
  time: {
    weeks: 'Weeks',
    week: 'Week',
    months: 'Months',
    month: 'Month',
    years: 'Years',
    year: 'Year',
  },
  select: {
    role: 'Select Role',
    facility: 'Select Facility',
    status: 'Select Status',
    sport: 'Select Sport',
    field: 'Select Field',
    chunk: 'Select Chunk',
    payment_method: 'Select Payment Method',
    group: 'Select Group',
    action: 'Select Action',
    date_range: 'Select Date Range',
  },
  status: {
    status: 'Status',
    active: 'Active',
    inactive: 'Inactive',
    pending: 'Pending',
    paid: 'Paid',
    free: 'Free',
    hold: 'Hold',
    processing: 'Processing',
    scheduled: 'Scheduled',
    cancelled: 'Cancelled',
    successful: 'Successful',
    declined: 'Declined',
    refunded: 'Refunded',
    partial_refund: 'Partial Refund',
    recording: 'Recording',
    basic: 'Basic',
    premium: 'Premium',
    tracked: 'Tracked',
    static: 'Static',
    confirmed: 'Confirmed',
  },
  users: {
    users: 'Users',
    user: 'User',
    dni: 'DNI',
    type: 'User Type',
    create: 'Create User',
    edit: 'Edit User',
    show: 'Show User',
    list: 'Users List',
    username: 'Username',
    name: 'Name',
    id: 'ID User',
    firstname: 'First Name',
    lastname: 'Last Name',
    fullname: 'Fullname',
    email: 'Email',
    phone: 'Phone',
    gender: 'Gender',
    male: 'Male',
    female: 'Female',
    other: 'Other',
    bio: 'Bio',
    birthday: 'Birthday',
    address: 'Address',
    password: 'Password',
    password_conf: 'Confirm password',
    password_not_match: 'Passwords do not match',
    password_restore: 'Restore password',
    save_password: 'Save password',
    account: 'Account',
    security: 'Security',
    info: 'Info',
    overview: 'Overview',
    new_password: 'New Password',
    confirm_new_password: 'Confirm New Password',
    password_hint: "Make sure it's between 6 and 20 characters.",
    contact: 'Contact',
    users_app: 'Users App',
    country: 'Country',
    state: 'State',
    state_province_region: 'State / Province / Region',
    city: 'City',
    register_date: 'Register Date',
    register_hour: 'Register Hour',
    last_login: 'Last Login Date',
    last_login_hour: 'Last Login Hour',
    website: 'Website',
    user_activity: 'User Activity',
    experience: 'Experience',
    age: 'Age',
    consent: 'Consent',
  },
  sports: {
    sports: 'Sports',
    sport: 'Sport',
    id: 'ID Sport',
    name: 'Name',
    time: 'Playing Time',
    description: 'Description',
    without_description: 'Without Description',
    ranking_group: 'Ranking Group',
    max_score_diff: 'Maximum Score Difference',
    smsd: 'SMSD',
    score_k_factor: 'Score K-Factor',
  },
  transactions: {
    id: 'Transaction ID',
    transactions: 'Transactions',
    transaction: 'Transaction',
    paid: 'Paid',
    client: 'Client',
    video_date: 'Video Date',
    field: 'Field',
    start_time: 'Start Time',
    end_time: 'End Time',
    start_date: 'Start Date',
    end_date: 'End Date',
    blocked_date: 'Blocked Date',
    video_time: 'Video Time',
    amount: 'Amount',
    client_amount: 'Client Amount',
    add_payment: 'Add Payment',
    pay: 'Pay',
    date: 'Date',
    payment_date: 'Payment Date',
    payment_amount: 'Payment Amount',
    payment_method: 'Payment Method',
    note: 'Note',
    video_info: 'Video Info',
    user_name: 'User/Name',
    transaction_date: 'Transaction Date',
    order_date: 'Order Date',
    tax: 'Tax',
    discount: 'Discount',
    pay_now: 'Pay Now',
    date_issued: 'Date Issued',
    visit_us: 'Visit Us',
    contact_us: 'Contact Us',
    email_us: 'Email Us',
    call_us: 'Call Us',
    price: 'Price',
    invoice_to: 'Invoice To',
    receipt_to: 'Receipt To',
    seller: 'Seller',
    thanks_buy: 'Thanks for partnering with us.',
    bill_to: 'Bill To',
    due_date: 'Due Date',
    order: 'Order',
    orders: 'Orders',
    order_by: 'Order by',
    cancel_order: 'Cancel Order?',
    refund: 'Refund if payment was made',
    inactivate_video: 'Inactivate Video',
    confirm_cancel: 'Yes, Cancel Order!',
    from_date: 'From Date',
    to_date: 'To Date',
    description: 'Description',
    monthly_report: 'Monthly Report',
    monthly_orders: 'Monthly Orders',
    hours: 'Hours',
    purchase_site: 'Purchase Site',
    payment_receipt: 'Payment Receipt',
    payment_agent: 'Payment Agent',
  },
  fields: {
    field: 'Field',
    fields: 'Fields',
    pricing: 'Pricing',
    hour: 'Hour',
    hourly_pricing: 'Facility Hourly Price',
    user_hourly_price: 'User Hourly Price',
    h_pricing: 'H. Price',
    pricing_type: 'Pricing Types',
    general: 'General',
    images: 'Images',
    chunk_frecuency: 'Chunk Frecuency',
    name: 'Name',
    schedule: 'Schedule',
    time_open: 'Time Open',
    time_close: 'Time Close',
    set_time: 'Set Time',
    weekday: 'Weekday',
    open: 'Open',
    close: 'Close',
    open_time: 'Open Time',
    close_time: 'Close Time',
    update_open_time: 'Update Open Time',
    update_close_time: 'Update Close Time',
    clear_hourly: 'Clear Hourly',
    link: 'Link',
    link_web: 'Web Link',
    link_app_landing: 'App Landing Link',
    link_video: 'Video Link',
    hourly_discount: 'Hourly Discount',
    recording_type: 'Recording Type',
    static_video: 'Static video',
    tracked_video: 'Tracked video',
    basic_video: 'Basic video',
    premium_video: 'Premium video',
    description_images: 'Description field images',
    players_qty: 'Players Quantity',
    booking_hourly_price: 'Booking Hourly Price',
    allow_public_cards: 'Allow public cards',
    premium_video_available: 'Video premium available',
    shared_fields: 'Shared Fields',
    blocked_by_shared_field: 'Blocked by shared field',
    blocked_time: 'Blocked time',
  },
  facilities: {
    facilities: 'Facilities',
    facility: 'Facility',
    signer_name: 'Signer Name',
    facility_owner: 'Facility Owner',
    facility_representative: 'Facility Representative',
    administrator: 'Administrator',
    add_comission: 'Add Comission?',
    post_Sale: 'Post-Sale Comission',
    tax_percent: 'Tax Percent',
    logo: 'Logo',
    select_avatar: 'Select Avatar',
    short_address: 'Short Address',
    billing_cycle: 'Billing Cycle',
    general: 'General',
    location: 'Location',
    schedule: 'Schedule',
    images: 'Images',
    bank_account: 'Bank Account',
    fanaty: 'FANATY',
    enter_location: 'Enter your location',
    monthly_min_orders: 'Monthly Min Orders',
    create_group: 'Create organization',
    add_to_group: 'Add to organization',
    google_place_id: 'Google Place Id',
    required_order_phone_number: 'Required Order Phone Number',
    timezone: 'Time Zone',
    video_declaration: 'Acceptance declaration of video',
    account_number: 'Account number',
    account_holder_name: 'Account holder name',
    account_routing_number: 'Routing number',
    account_bank_name: 'Account bank name',
    connect: 'Connect',
    on_boarding_link: 'On Boarding Link',
    go_to_dashboard: 'Go to dashboard',
    link_will_expire: 'This link will expire in 5 minutes!',
  },
  billing_cycle_types: {
    immediately: 'Immediately',
    daily: 'Daily',
    monthly: 'Monthly',
    manually: 'Manually',
    weekly: 'Weekly',
    every_two_weeks: 'Every 2 weeks',
    frequency: 'Frequency',
  },
  roles: {
    roles: 'Roles',
    role: 'Role',
    admin: 'Admin',
    facility: 'Facility',
    representative: 'Representative',
    facility_owner: 'Facility Owner',
    facility_representative: 'Facility Representative',
    administrator: 'Administrator',
    name: 'Name',
    description: 'Description',
    general: 'General',
    permissions: 'Permissions',
  },
  cameras: {
    cameras: 'Cameras',
    camera: 'Camera',
    code: 'Code',
    general: 'General',
    thumbnail: 'Thumbnail',
    details: 'Details',
    mac_address: 'Mac Address',
    date: 'Thumbnail Date',
    last_date: 'Last Thumbnail Date',
    description: 'Description',
    name: 'Name',
    refresh_chunk: 'Refresh Chunks',
  },
  videos: {
    videos: 'Videos',
    video: 'Video',
    date: 'Date',
    time: 'Time',
    highlights: 'Highlights',
    highlight: 'Highlight',
    add_order: 'Add Order',
    minutes: 'Minutes',
    add_order_video: 'Add Order Video',
    get_app: ' Get the App Free!',
    dont_have_fanaty: "Don't have Fanaty?",
    using: ' is using Fanaty.',
    app_all: 'Everyone is using Fanaty.',
    video_cost: 'Video cost will be calculated at confirm order',
    views: 'Views',
    show_qr: 'Show QR',
    general: 'General',
    payment: 'Payment',
    first_video_viewed: 'First video viewed',
    first_video_added: 'First video added',
    to_edit: 'to edit',
    start_time: 'Start time',
    make_recurring: 'Make recurring',
    weekdays: 'Weekdays',
    left_video: 'Left Video',
    right_video: 'Right Video',
    left_right_video: 'Left/Right Video',
    video_type: 'Video Type',
    ip_address: 'IP Address',
    orders_till: 'Orders till',
    will_be_created: 'will be created automatically after save',
    create_recurring: 'Yes, Create Recurring Order!',
    edit_recurring: 'Yes, Edit Recurring Order!',
    download_app: 'Download App',
    repeat: 'Repeat',
    video_not_available: 'Cannot download video after 30 days',
  },
  video_details: {
    duration: 'Duration',
    time: 'Time',
    date: 'Date',
    facility: 'Facility',
    field: 'Field',
    sport: 'Sport',
    amount: 'Video price',
    discount: 'Pre-Order discount',
    final_amount: 'Client final amount',
    comission: 'Comission',
    tax: 'Sales tax',
    group: 'Group',
    start_time: 'Start time',
    total_time: 'Total Time',
    refunds: 'Refunds',
    video_type: 'Video Type',
  },
  highlights: {
    highlights: 'Highlights',
    highlight: 'Highlight',
    created_date: 'Created Date',
    created_time: 'Created Time',
    highlight_name: 'Highlight Name ',
    created_by: 'Created By',
    duration: 'Duration',
    category: 'Category',
    type: 'Highlight type',
    goal: 'Goal',
    foul: 'Foul',
    assist: 'Assist',
  },
  info: {
    visit_us: 'Visit Us:',
    contact_us: 'Contact Us:',
  },
  payment_methods: {
    payment_methods: 'Payment Methods',
    payment_method: 'Payment Method',
    add_card: 'Add card',
    card_exp: 'Card expires at',
    edit_address: 'Edit Address',
    billing_address: 'Billing Address',
    company_name: 'Company Name',
    billing_email: 'Billing Email',
    billing_phone: 'Billing Phone',
    contact: 'Contact',
    zip_code: 'Zip Code',
    edit_address_info: 'Edit card for future billing',
    new_card: 'New Card',
    new_address_info: 'New card for future billing',
    card_number: 'Card Number',
    name_card: 'Name On Card',
    expiration_date: 'Expiration Date',
    cvv: 'CVV',
    default_card: 'Default Card?',
    state: 'State',
    edit_billing_address: 'Edit Billing Address',
    edit_Card: 'Edit Card',
    payment_date: 'Payment Date:',
    payment: 'Payment',
    brand: 'Brand',
    price: 'Price',
    payment_info: 'Payment Info',
  },
  error_404: {
    page_not_found: 'Page Not Found',
    error_message: "we couldn't find the page you are looking for",
    back_home: 'Back to home',
  },
  field_app: {
    open_app: 'Open in Fanaty App',
    dont_have: "Don't have the app?",
    download: 'Download it now!',
  },
  video_app: {
    watch_video: 'Watch Video',
    reproduced: 'Videos can only be reproduced in the mobile app.',
    video_not_found: 'The video does not exist!',
    content_not_exist: "The content doesnt's exist anymore!",
    content_delete: 'Content deleted',
  },
  login: {
    welcome: 'Welcome to',
    message: 'Please sign-in to your account and start the adventure',
    username: 'Username',
    password: 'Password',
    forgot_password: 'Forgot Password?',
    login: 'Login',
    welcome_to_platform: 'Management Platform',
    message_platform: 'Please sign-in to manage your organization',
  },
  settings: {
    logout: 'Logout',
    change_password: 'Change Password',
    payment_method_name: 'Name',
    payment_method_desc: 'Description',
    onSite_payment_method: 'OnSite Payment Method',
    payment_method_title: 'OnSite Payment Methods',
    payment_method_new: 'New On Site Payment Method',
  },
  table: {
    item_page: 'Rows per page:',
    sort_by: 'Sort By',
    no_data: 'No data available',
    loading_text: 'Loading data...',
  },
  miscellaneous: {
    you_are_not_authorized: 'You are not authorized',
    you_dont_have_permission: 'You don’t have permission to access this page. Go Home',
    back_to_home: 'Back to home',
    page_not_found: 'Page Not Found',
    we_couldnt_find_the_page: "we couldn't find the page you are looking for",
    no_video_on_web: 'Download the mobile app to watch',
    please_wait: 'Please wait...',
  },
  notifications: {
    notifications: 'Notifications',
    notification: 'Notification',
    title_spa: 'Title SPA',
    title_eng: 'Title ENG',
    description_spa: 'Description SPA',
    description_eng: 'Description ENG',
    button_label_spa: 'Button label SPA',
    button_label_eng: 'Button label ENG',
    image: 'Image',
    background: 'Background',
    handled_action: 'Handled action',
    show_modal: 'Show Modal',
    select_background: 'Select a background',
    select_image: 'Select a image',
    only_fanaty_user: 'Only Fanaty user?',
    title: 'Title',
    text: 'Text',
    text_html: 'Text HTML',
    border: 'Border',
    color: 'Color',
    dismissible: 'Dismissible',
    outlined: 'Outlined',
    colored_text: 'Colored Text',
    type: 'Type',
    icon: 'Icon',
    all_groups: 'All Groups',
    all_roles: 'All Roles',
    send_by: 'Sent by',
    created_on: 'Created on',
  },
  groups: {
    groups: 'Groups',
    group: 'Group',
    min_orders_amount: 'Min. order amount',
    min_orders_filter: 'Min. order filter',
    all_groups: 'All groups',
    monthly_charge_amount: 'Monthly Suscription Amount',
    order_reminder_minutes: 'SMS Reminder Minutes',
    notes: 'Notes',
    price_plan: 'Price plan',
    price_range: 'Price range',
    hour_price_range: 'Hour Price Range',
    from: 'From',
    to: 'to',
    up: 'and up',
    hours: 'hours',
    hour: 'hour',
    add_range: 'Add range',
    range_close: 'You must complete the range price!',
    fixed_minimum_amount: 'Fixed minimum amount',
    price_hour_range: 'Price per hour range',
    only_hourly_price: 'Only hourly Price',
    fanaty_service_fee: 'Online payments service fee in $',
    fanaty_service_fee_percent: 'Online payments service fee in %',
    cancellation_hours_qty: 'Cancellation Policy (hs)',
  },
  statistics: {
    this_month: 'This Month',
    overview: 'Weekly Orders Number',
    orders: 'Orders amount',
    order_number: 'Number of orders',
    app_users_number: 'Active App Users',
    app_users_number_tooltip: 'Quantity of unique users that interacted with the videos of the facility during the specified period of time. Opening the app does not count as an interaction and the same user might count in multiple facilities',
    comissions: 'Comissions',
    active_facility: 'Active Facilities',
    active_field: 'Active Fields',
    active_camera: 'Active Cameras',
    active_app_users: 'Active App Users',
    active_app_users_tooltip: 'Quantity of unique users that interacted with the app during the specified period of time. Opening the app does not count as an interaction',
    app_sessions: 'App Users Bounce Rate',
    app_sessions_tooltip: 'Percentage of users that open the mobile app but does not interact with any video',
    number_videos: 'Played Videos',
    number_videos_tooltip: 'Played Videos',
    unique_views: 'Unique views',
    unique_views_tooltip: 'Quantity of unique views on all videos. Users are count once per video, but might be counted in multiple videos.',
    avg_per_video: 'Avg per video',
    avg_per_video_tooltip: 'Average Video Views Played',
    sales_by_facility: 'Sales by facility',
    actions_app_by_user: 'Actions App by User',
    actions_app_by_user_tooltip: 'Quantity of interactions per user. Opening the mobile app does not count as an interaction',
    registered_app_users: 'Registered App Users',
    registered_app_users_by_facility: 'Registered App Users by Facility',
    unique_views_by_facility: 'Unique Views by Facility',
    refunds_amount: 'Refunds Amount',
    actives_facility: 'Actives Facility',
    actives_field: 'Actives Field',
    actives_camera: 'Actives Cameras',
  },
  alert_types: {
    success: 'Success',
    error: 'Error',
    warning: 'Warning',
    info: 'Info',
  },
  border_types: {
    left: 'Left',
    right: 'Right',
    top: 'Top',
    bottom: 'Bottom',
  },
  colors: {
    red: 'Red',
    orange: 'Orange',
    yellow: 'Yellow',
    green: 'Green',
    blue: 'Blue',
    purple: 'Purple',
  },
  date_range: {
    this_year: 'This year',
    this_month: 'This month',
    last_month: 'Last month',
    this_week: 'This week',
    last_week: 'Last week',
    custom: 'Custom',
  },
  notify_players: {
    notify_players: 'Notify Players',
    send_sms: 'Send SMS',
    phone: 'Player phone number',
    add_phone: 'Add phone',
    message: 'A video link will be sent via SMS to each player added.',
    skip: 'Skip',
    requiredPhone: 'You must add at least one phone number',
    you_must_add: 'You must add a phone number if you make order recurring.',
    phone_numbers: 'Phone numbers',
    organizer: 'Organizer',
    organizers: 'Organizers',
    members: 'Members',
    game_organizer: 'Game Organizer',
    organizer_phone: 'Organizer phone number',
    other_phone_numbers: 'Other phone numbers',
  },
  dashboard: {
    shared_highlights: 'Shared Highlights',
    numbe_of_highlights: 'Number of highlights links shared',
    created_highlights: 'Created Highlights',
    numbe_of_highlights_created: 'Number of highlights created',
    revenue: 'Revenue',
    orders_revenue: 'Orders revenue for a time period',
  },
  players_group: {
    players_group: 'Players Group',
    captain: 'Captain',
    cocaptain: 'Coaptain',
    captain_phone_number: 'Captain Phone Number',
    cocaptain_phone_number: 'Cocaptain Phone Number',
    captain_email: 'Captain Email',
    cocaptain_email: 'Subcaptain Email',
    type: 'Type',
    visibility: 'Visibility',
    private: 'Private',
    public: 'Public',
  },
  reservations: {
    reservations: 'Bookings',
    reservation: 'Booking',
    new_reservation: 'New Booking',
    reservation_detail: 'Booking Detail',
    reservation_save: 'Save Booking',
    cancel_reservation: 'Cancel Booking',
    recurring_etail: 'Recurring Detail',
    recurring: 'Recurring',
    one_day: 'One Day',
    pick_up: 'Pick Ups ',
    pick_up_match: 'Pickup Match',
    regular_match: 'Regular Match',
    group_match: 'Group Match',
    regular: 'Regular',
    other: 'Other',
    blocked: 'Blocked',
    academy: 'Academy',
    academies: 'Academies',
    match: 'Match',
    league: 'League',
    birthday: 'Birthday',
    tournament: 'Tournament',
    insights: 'Insights',
    person: 'Person',
    allow_video: 'Allow Video',
    include_video: 'Include Video',
    no_included: 'No included',
    player: 'Player',
    guest: 'Guest',
    per_player: 'Per Player',
    all_members: 'All Group Members',
    confirmed_players: 'Confirmed Players',
    add_group_players: 'Add Group Players',
    add_other_players: 'Add Other Players',
    balance: 'Balance',
    mark_all_paid: 'Mark all paid',
    no_show: 'No Show',
    other_reason: 'Other Reason',
    reason: 'Reason',
    incomplete_reservations: 'Incomplete Bookings',
    repeat_n_weeks: 'Repeat every N weeks',
    frequency: 'Frecuencia',
    incomplete_reason: 'Incomplete Reason',
    not_created: 'Not created due to conflicts',
    contact_name: 'Contact Name',
    contact: 'Contact',
    contacts: 'Contacts',
    contact_phone_numbers: 'Contact Phone Numbers',
    search_contact: 'Search for your contact or',
    enter_name_phone: 'Enter name or phone',
    enter_name_phone_email: 'Enter name, phone or email',
    add_new_contact: 'Add new contact',
    add_new_participant: 'Add new participant',
    add_new_group: 'Add new players group',
    group_match_detail: 'Group Match Detail',
    pick_up_match_detail: 'Pick Up Match Detail',
    regular_match_detail: 'Regular Match Detail',
    recurrency_detail: 'Recurrency Detail',
    skill_level: 'Skill Level',
    price_per_player: 'Price per Player',
    min_players: 'Min Players',
    max_players: 'Max Players',
    hours_before_cancel: 'Hours before cancel match (If minimum players not join)',
    reservation_link_copied: 'Reservation link copied!',
    reservation_link_error: 'Error copying reservarion link!',
    delete_all_pending: 'Delete all pending bookings',
    no_ncluded: 'No included',
    scheduled: 'Scheduled',
    reservation_link: 'Reservation link',
    reservation_schedule_conflict: 'Reservation schedule conflict',
    days_with_conflict: 'Days with conflict',
    create_reservations_without_conflict: 'Create bookings without conflict',
    no_active_fields: 'The facility is closed this day',
    payment_status: 'Payment status',
    inc_taxes_add_ons: 'includes taxes and add-ons',
    mark_as_paid: 'Mark as Paid',
    video_recording: 'Video Recording',
    flat_rate: 'Flat Rate',
    per_participant: 'Per Participant',
    pricing_method: 'Pricing Method',
    participants: 'Participants',
    participant: 'Participant',
    owner: 'Owner',
    organizer: 'Organizer',
    optional: 'optional',
    tota_includ_taxes_addons: 'Total include taxes and add-ons',
    date_and_time: 'Date and Time',
  },
  reservation_detail: {
    reservation_detail: 'Reservation Detail',
    edit_details: 'Edit Details',
    chat: 'Chat',
    notes: 'Notes',
    history: 'History',
    origin: 'Origin',
    price_per_participant: 'Price per participant',
    cancel_booking: 'Cancel Booking',
    cancel_booking_info: 'If payment werw made, they will be refunded automatically (online service fees are non-refundable). If a video was ordered, it will be cancelled without charge or a refund will be issued if the booking hasn\'t started.',
    delete_booking: 'Delete booking',
    delete_only_this_booking: 'Delete only this booking',
    delete_all_the_following: 'Delete all the following',
  },
  skill_lavels: {
    rookie: 'Rookie',
    intermediate: 'Intermediate',
    pro: 'Pro',
  },
  organizations: {
    organization: 'Organization',
    organizations: 'Organizations',
    organization_owner: 'Organization Owner',
    all_organization: 'All Organizations',
  },
  products: {
    products: 'Products',
    product: 'Product',
    product_list: 'Product List',
    product_add: 'Add Product',
    name: 'Name',
    dr: 'Days Remaining',
    dozi: 'Day of zero Inventory',
    stock: 'Current Stock',
    products_categories: 'Products Categories',
    products_categories_name: 'Name',
    products_categories_desc: 'Descriptión',
    inventory: 'Inventory',
    current_inventory: 'Current Inventory',
    inventory_creator: 'Creator',
    adjustment_cost: 'Adjustment Cost',
    adjusted_products: 'Adjusted Products',
    inventory_id: 'ID',
    inventory_date: 'Date',
    mdwi: 'MDWI',
    categories: 'Categories',
    inventory_item: 'Item',
    inventory_system: 'System',
    inventory_stock: 'Stock',
    inventory_plus: '+/-',
    reason: 'Reason',
    obs: 'Obs',
    inventory_adjustment: 'Inventory Adjustment',
    inventory_observation: 'Observations',
    product_adjustment: 'Product Adjustment',
    unit: 'Unit',
    system: 'System Stock',
    attach: 'Attach',
    observation: 'Observation',
    damaged: 'Damaged',
    expired: 'Expired',
    lost: 'Lost',
    product_charge: 'Product Charge',
    qty_charge: 'Quantity Charge',
    qty: 'Quantity',
    new_stock: 'New Stock',
    confirm: 'Confirm',
    cart: 'Cart',
    payment: 'Payment',
    confirmation: 'Confirmation',
    guest: 'Guest',
    visibility: 'Visibility',
    individual: 'Individual',
    both: 'Both',
  },
  terminals: {
    terminals: 'Terminals',
    terminal: 'Terminal',
    name: 'Name',
    description: 'Description',
    stripe_reader: 'Stripe Reader',
    reference_reader: 'Reader Reference',
    operators: 'Operators',
    serial: 'Serial',
    terminal_name: 'Terminal Name',
  },
  checkout: {
    sales_details: 'Sales Details',
    price_details: 'Price Details',
    taxes_and_fees: 'Taxes and Fees',
    tip: 'Tip',
    place_order: 'Place Order',
    open_tabs: 'Open Tabs',
    open_tab: 'Open Tab',
    keep_open: 'Keep Open',
    reader: 'Reader',
    cash: 'Cash',
    manual: 'Manual',
    others: 'OTHERS',
    continue_to_read: 'Press continue to read the card',
    cash_amount: 'Cash amount',
    change: 'Change',
    thank_you: 'Thank You! 😇',
    we_sent_an_email: 'We sent an email to',
    with_your_invoice: 'with your invoice.',
    finish: 'Finish',
  },
  video_cancelled: {
    video_cancelled: 'Video Cancelled',
    game_cancelled: 'Game Cancelled',
    game_info: 'This game has been cancelled. Search games to see if a new one was created.',
    search_games: 'Search Games',
    search_videos: 'Search Videos',
    download_app: 'Download the app',
    not_available: 'Video Not Available',
    not_valid: 'Vídeo not valid',
    click_search_videos: 'Click on search videos to find others.',
  },
  questions: {
    are_you_sure: 'Are you sure?',
    reason_by_modified: 'The reason name will be changed in the report. If you want another reason, it can be added and this can be disabled.',
    yes: 'Yes',
    no: 'No',
  },
  locales: {
    languages: 'Languages',
    language: 'Language',
    es: 'Spanish',
    en: 'English',
  },
  contacts: {
    contacts: 'Contacts',
    contact: 'Contact',
  },
}
